<template>
    <div>
        <h1>Inicio de sesion con {{ socialParam }} exitoso!, espere un momento...</h1>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "socialLogin",
    data() {
        return {
            user: ''       
        };
    },
    computed: {
        socialParam() {
            return this.$route.params.social;
        }
    },
    methods: {
        ...mapActions({
            socialLoginCallback: "auth/socialLoginCallback",
        }),
        async login() {
            this.socialLoginCallback({
                code: this.$route.query.code,
                social: this.socialParam
            }).then((response) => {
                // logic authenticated successfull
                console.log('googleCallback', response);
                this.$router.push('/');
                window.close();
            }).catch((error) => {
                console.log('error social callback', error)
            })
        }
    },
    created() {
        this.login();
    }
};
</script>
<style></style>
